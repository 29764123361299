// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-page-js": () => import("./../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-blog-post-js": () => import("./../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-contact-page-js": () => import("./../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-index-page-js": () => import("./../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-news-page-js": () => import("./../src/templates/news-page.js" /* webpackChunkName: "component---src-templates-news-page-js" */),
  "component---src-templates-terms-page-js": () => import("./../src/templates/terms-page.js" /* webpackChunkName: "component---src-templates-terms-page-js" */),
  "component---src-templates-work-item-js": () => import("./../src/templates/work-item.js" /* webpackChunkName: "component---src-templates-work-item-js" */),
  "component---src-templates-work-page-js": () => import("./../src/templates/work-page.js" /* webpackChunkName: "component---src-templates-work-page-js" */)
}

